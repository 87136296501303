import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: '50px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 30px',
    },
  },
  title: {
    fontSize: 24,
    color: theme.palette.primary.light,
    marginBottom: 20,
  },
  block: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[350],
    padding: '20px 50px',
  },
  blockTitle: {
    fontSize: 24,
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  blockSubTitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  link: {
    fontSize: 11,
    fontWeight: 600,
    color: theme.palette.primary.main,
    '& svg': {
      marginRight: 5,
    },
    '& + &': { marginTop: 5 },
  },
}));
