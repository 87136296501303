import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Reset the error state on route changes.
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  renderError = () => {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorIcon color="action" style={{ fontSize: 48 }} />
        <Typography variant="h5" color="textSecondary" paragraph style={{ margin: 16 }}>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          This page could not load correctly. Please go back to the previous page and try again.
        </Typography>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.hasError && this.renderError()}
        {!this.state.hasError && this.props.children}
      </>
    );
  }
}

export default withRouter(ErrorBoundary);
