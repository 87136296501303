import PATHS from './paths';
import DevTools from '../views/swaggers/Swaggers';

const routes = [
  {
    path: PATHS.swaggers,
    component: DevTools,
    permissions: [],
    sideRail: true,
  },
];

export default routes;
