import palette from '../palette';

export default {
  overrides: {
    MuiDivider: {
      light: {
        backgroundColor: palette.grey[100],
      },
    },
  },
  props: {
    MuiDivider: {},
  },
};
