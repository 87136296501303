import React from 'react';
import { ReactComponent as IconCheck } from 'assets/icons/icon-radio-check.svg';

export default {
  overrides: {
    MuiRadio: {
      root: {
        '&.Mui-disabled.Mui-checked': {
          opacity: 0.5,
        },
      },
    },
  },
  props: {
    MuiRadio: {
      checkedIcon: <IconCheck />,
    },
  },
};
