import palette from '../palette';

export default {
  overrides: {
    MuiButton: {
      root: {
        padding: '8px 12px 8px 14px',
        borderRadius: 0,
        fontSize: 11,
        fontWeight: 600,
      },
      contained: {
        color: palette.primary.main,
        '&.Mui-disabled': {
          background: palette.grey[350],
          color: palette.grey[300],
        },
      },
      containedPrimary: {
        color: palette.grey[200],
      },
      textPrimary: {
        color: palette.primary.main,
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
};
