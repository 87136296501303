import _ from 'lodash';
import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';

import tableRowDefaults from './materialDefaults/tableRow';
import buttonDefaults from './materialDefaults/button';
import radioDefaults from './materialDefaults/radio';
import formLabelDefaults from './materialDefaults/formLabel';
import menuDefaults from './materialDefaults/menu';
import dividerDefaults from './materialDefaults/divider';
import tooltipDefaults from './materialDefaults/tooltip';
import checkboxDefaults from './materialDefaults/checkbox';
import linkDefaults from './materialDefaults/link';

const theme = {
  palette,
  typography,
  shape: {
    borderRadius: '4px',
  },
};

const mergedTheme = _.merge(
  theme,
  tableRowDefaults,
  buttonDefaults,
  radioDefaults,
  formLabelDefaults,
  menuDefaults,
  dividerDefaults,
  tooltipDefaults,
  checkboxDefaults,
  linkDefaults
);

export default createMuiTheme(mergedTheme);
