export default {
  overrides: {
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
  },
  props: {
    MuiTableRow: {},
  },
};
