import React from 'react';
import './styles/App.css';
import './styles/resets.css';
import './styles/defaults.css';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Link as RouterLink,
  Switch as RouterSwitch,
} from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import AppContextProvider from 'store/CombinedContextProvider';
import AxiosInterceptors from 'api/axiosInterceptors';

import PrivateRoute from './components/route/PrivateRoute';
import { Grid, Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from 'styles/theme';
import ApplicationShell from './views/layout/ApplicationShell';
// import SignIn from "./views/auth/SignIn";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    minWidth: 500,
    minHeight: 350,
  },
  logo: {
    height: 80,
  },
}));

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DndProvider backend={Backend}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={5}
            autoHideDuration={5000}
            variant="success"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Router>
              <CssBaseline />
              <AppContextProvider>
                <AxiosInterceptors>
                  <RouterSwitch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to={{ pathname: '/app/swaggers' }} />}
                    />
                    {/*<Route path="/signin" component={SignIn}/>*/}
                    <PrivateRoute path="/app" component={ApplicationShell} />
                    <Route path="*">
                      <NoMatch />
                    </Route>
                  </RouterSwitch>
                </AxiosInterceptors>
              </AppContextProvider>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </DndProvider>
    </MuiPickersUtilsProvider>
  );
}

function NoMatch() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={4}>
        <Grid container justify="space-around" alignItems="center" className={classes.paper}>
          <Grid item>
            <h1>404</h1>
            <h5>Page Not Found</h5>
            <br />
            <br />
            <Button color="primary" variant="contained" component={RouterLink} to="/app/dashboard">
              Return to Dashboard
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default App;
