import React from 'react';
import { ReactComponent as CheckboxIcon } from 'assets/icons/icon-checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/icon-checkbox-checked.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from 'assets/icons/icon-checkbox-indeterminate.svg';

import palette from '../palette';

export default {
  overrides: {
    MuiCheckbox: {
      root: {
        '&.Mui-disabled.MuiIconButton-label': {
          background: palette.grey[800],
        },
        '&.Mui-disabled svg': {
          opacity: 0.3,
        },
      },
    },
  },
  props: {
    MuiCheckbox: {
      icon: <CheckboxIcon />,
      checkedIcon: <CheckboxCheckedIcon />,
      indeterminateIcon: <CheckboxIndeterminateIcon />,
    },
  },
};
