// import React, {useState} from 'react';
import React from 'react';
// import {useAuth} from '../../../context/AuthContext';
import {
  AppBar,
  // Avatar,
  Button,
  // ClickAwayListener,
  // Divider,
  // Fade,
  // Grid,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  // Paper,
  // Popper,
  Toolbar,
  Typography,
  // Tooltip
} from '@material-ui/core';
// import ExitToApp from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import FaceIcon from '@material-ui/icons/Face';
// import PATHS from '../../../router/paths'
// import HeaderSearchField from './HeaderSearchField';
import useStyles from './styles';

function Header(props) {
  const classes = useStyles();

  const { className } = props;
  // const {history, className} = props;
  // const profileAnchorRef = React.useRef(null);
  // const {profile, invalidateToken} = useAuth();
  //
  // const [showProfileMenu, setShowProfileMenu] = useState(false);

  // function handleSearchSuggestionSelect(suggestion) {
  //   // todo-phase-2: move routes to separate file
  //   switch (suggestion.type) {
  //     default:
  //       throw new Error(`Action Type: ${suggestion.type} not supported.`);
  //   }
  // }

  // const handleProfileMenuClick = () => {
  //   setShowProfileMenu((prevOpen) => !prevOpen);
  // };
  //
  // const handleViewProfileClick = () => {
  //   setShowProfileMenu(false);
  //   history.push(`/app/user/profile`);
  // };
  //
  // const handleManageUsersClick = () => {
  //   setShowProfileMenu(false);
  //   history.push(PATHS.administration.overview);
  // };
  //
  // const handleClose = (event) => {
  //   if (profileAnchorRef.current && profileAnchorRef.current.contains(event.target)) {
  //     return;
  //   }
  //
  //   setShowProfileMenu(false);
  // };
  //
  // const handleSignOut = () => {
  //   history.push('/signin');
  //   invalidateToken();
  // };

  return (
    <AppBar position="fixed" className={className} color="transparent">
      <Toolbar className={classes.wrapper}>
        <Typography variant="h4" noWrap className={classes.appTitle}>
          <span className={classes.logoStart}>Ruby</span>
          <span className={classes.logoEnd}>Dev</span>
        </Typography>

        <div className={classes.grow} />

        {/*{<HeaderSearchField onSelect={handleSearchSuggestionSelect}/>}*/}

        <Button
          style={{ display: 'none' }}
          classes={{ root: classes.button }}
          startIcon={<HelpOutlineIcon />}
        >
          Knowledge
        </Button>

        {/*<Button*/}
        {/*classes={{root: classes.button}}*/}
        {/*startIcon={<FaceIcon/>}*/}
        {/*ref={profileAnchorRef}*/}
        {/*onClick={handleProfileMenuClick}*/}
        {/*>*/}
        {/*{profile.username}*/}
        {/*</Button>*/}

        {/*<Popper*/}
        {/*anchorEl={profileAnchorRef.current}*/}
        {/*placement="top"*/}
        {/*open={showProfileMenu}*/}
        {/*transition*/}
        {/*className={classes.suggestions}*/}
        {/*>*/}
        {/*{({TransitionProps}) => (*/}
        {/*<Fade {...TransitionProps} timeout={350}>*/}
        {/*<Paper>*/}
        {/*<ClickAwayListener onClickAway={handleClose}>*/}
        {/*<Grid container justify="center" direction="column">*/}
        {/*<Grid item xs={12} container justify="center" alignItems="center">*/}
        {/*<Grid*/}
        {/*item*/}
        {/*xs={12}*/}
        {/*container*/}
        {/*alignItems="center"*/}
        {/*direction="column"*/}
        {/*style={{padding: 16}}*/}
        {/*>*/}
        {/*<Avatar aria-label="profile-name" className={classes.avatar}>*/}
        {/*{profile.username.substr(0, 1).toUpperCase()}*/}
        {/*</Avatar>*/}
        {/*<Tooltip title={profile.username}>*/}
        {/*<Typography*/}
        {/*className={classes.username}*/}
        {/*variant="subtitle2"*/}
        {/*display="block"*/}
        {/*>*/}
        {/*{profile.username}*/}
        {/*</Typography>*/}
        {/*</Tooltip>*/}
        {/*</Grid>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*<Divider/>*/}
        {/*<List dense>*/}
        {/*<ListItem button onClick={handleViewProfileClick}>*/}
        {/*<ListItemText primary={'View Profile'}/>*/}
        {/*</ListItem>*/}
        {/*<ListItem disabled>*/}
        {/*<ListItemText primary={'View Preferences'}/>*/}
        {/*</ListItem>*/}
        {/*<ListItem button onClick={handleManageUsersClick}>*/}
        {/*<ListItemText primary={'Manage Users'}/>*/}
        {/*</ListItem>*/}
        {/*</List>*/}
        {/*<Divider/>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*<List dense>*/}
        {/*<ListItem button onClick={handleSignOut}>*/}
        {/*<ListItemIcon>*/}
        {/*<ExitToApp/>*/}
        {/*</ListItemIcon>*/}
        {/*<ListItemText primary={'Sign Out'}/>*/}
        {/*</ListItem>*/}
        {/*</List>*/}
        {/*</Grid>*/}
        {/*</Grid>*/}
        {/*</ClickAwayListener>*/}
        {/*</Paper>*/}
        {/*</Fade>*/}
        {/*)}*/}
        {/*</Popper>*/}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
