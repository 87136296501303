import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import routes from '../../router/routes';
import PrivateRoute from '../../components/route/PrivateRoute';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from './header';

function ApplicationShell(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
    },
    pageContent: {
      marginTop: 64,
      width: '100%',
    },
    appBar: {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
    },
    logo: {
      display: 'flex',
      alignSelf: 'center',
      // height: 50,
      margin: theme.spacing(3),
    },
    navPanel: {
      height: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.light,
    },
    bottomPanel: {
      height: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[100],
      paddingLeft: 105,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
      backgroundColor: theme.palette.grey[100],
      height: 'calc(100vh - 144px)',
    },
  }));

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.Beacon('config', { display: { style: 'icon' } });
      window.Beacon('init', '625a8c0a-fc92-4672-b62c-c9d5388720ab');
    }
  }, []); //eslint-disable-line

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header className={classes.appBar} {...props} />
      <div className={classes.pageContent}>
        <div className={classes.navPanel}></div>
        <main className={classes.content}>
          <ErrorBoundary>
            {routes.map((route, i) => (
              <PrivateRoute
                key={i}
                path={route.path}
                component={route.component}
                permissions={route.permissions}
                routes={route.routes}
              />
            ))}
          </ErrorBoundary>
        </main>
        <div className={classes.bottomPanel}>© 2021 Ruby Has.</div>
      </div>
    </div>
  );
}

export default ApplicationShell;
