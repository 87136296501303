import { useMemo } from 'react';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';

const RequestInterceptor = ({ children }) => {
  const { invalidateToken, setLastActivity, token } = useAuth();

  useMemo(() => {
    axios.interceptors.response.use(
      async (res) => {
        res.ok = res.status >= 200 && res.status <= 299;

        return res;
      },
      async (error) => {
        if ([401, 403].indexOf(error?.response?.status) !== -1 && sessionStorage?.token) {
          invalidateToken();
          return;
        } else {
        }

        return Promise.reject(error?.response?.data);
      }
    );
    // eslint-disable-next-line
  }, [invalidateToken]);

  useMemo(() => {
    axios.interceptors.request.use((config) => {
      setLastActivity(Date.now());

      const userToken = token ? token : sessionStorage?.token;

      if (userToken) {
        config.headers.Authorization = `Bearer ${userToken}`;
      }
      return config;
    });
  }, [setLastActivity, token]);

  return children;
};

export default RequestInterceptor;
