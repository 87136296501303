export default {
  overrides: {
    MuiLink: {
      button: {
        fontSize: 11,
        fontWeight: 600,
      },
    },
  },
  props: {
    MuiLink: {
      underline: 'always',
    },
  },
};
