import palette from '../palette';

export default {
  overrides: {
    MuiTooltip: {
      tooltip: {
        borderRadius: 0,
        backgroundColor: palette.grey[750],
        color: '#ffffff',
        '&.MuiTooltip-tooltipPlacementTop': {
          margin: '6px 0',
        },
        '&.MuiTooltip-tooltipPlacementBottom': {
          margin: '6px 0',
        },
      },
    },
  },
  props: {
    MuiTooltip: {
      placement: 'top',
      arrow: true,
    },
  },
};
