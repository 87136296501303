import React from 'react';
import { Route } from 'react-router-dom';
// import { Redirect, Route } from 'react-router-dom';

// import { useProfile } from '../../context/ProfileContext';

function PrivateRoute({ component: Component, permissions = [], ...rest }) {
  // const { profile } = useProfile();
  // const hasAccess = true;

  return (
    <Route
      {...rest}
      render={(props) => {
        // if (!hasAccess)
        //   return <div>No access</div>;
        //
        // if (profile)
        return <Component {...props} {...rest} />;
        // else
        //   return <Redirect
        //    to={{
        //      pathname: '/signin',
        //      state: { from: props.location },
        //    }}
        //   />
      }}
    />
  );
}

export default PrivateRoute;
