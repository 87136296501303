import palette from '../palette';

export default {
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: -0.42,
        color: palette.text.primary,
        '&$focused': {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: -0.42,
        },
      },
    },
  },
  props: {
    MuiFormLabel: {},
  },
};
