import React, { useEffect, useState, useRef } from 'react';
import jwtDecode from 'jwt-decode';
import { login, refreshToken, loadUser } from 'api/requests/auth';

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [token, setToken] = useState(() => {
    return sessionStorage.getItem('token');
  });

  const [profile, setProfile] = useState(() => {
    return JSON.parse(sessionStorage.getItem('profile'));
  });

  const invalidateToken = React.useCallback(() => {
    setToken(null);
    setProfile(null);

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('profile');

    if (refreshIntervalRef.current) {
      clearInterval(refreshIntervalRef.current);
    }

    window.Beacon('destroy');
  }, []);

  const [lastActivity, setLastActivity] = useState(Date.now());
  const activityIntervalRef = useRef();

  useEffect(() => {
    // Schedule automatic sign-out after a set period of inactivity (Currently API Access).
    const intervalInMillis = 1000 * 60 * 60; // One Hour.
    const id = setInterval(() => {
      if (Date.now() - lastActivity > intervalInMillis) {
        invalidateToken();
      }
    }, 1000 * 30);

    activityIntervalRef.current = id;

    return () => clearInterval(activityIntervalRef.current);
  }, [lastActivity, invalidateToken]);

  const [refresh, setRefresh] = useState({});
  const refreshIntervalRef = useRef();

  useEffect(() => {
    // Begin a auto-refresh timer to renew the token if appropriate.
    if (refresh.token) {
      const fetchNewToken = async (prevToken) => {
        const data = new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: refresh.token,
        });

        const response = await refreshToken({ data: data });

        if (response.ok) {
          const result = handleResponse(response.data);
          const { access_token: token, statusText } = result;

          if (token) {
            setToken(token);
            sessionStorage.setItem('token', token);
          } else {
            throw new Error(statusText);
          }
        } else {
          invalidateToken();
        }
      };

      const intervalInMillis = refresh.expiresIn * 1000 - 1000 * 60 * 2; // Two minutes before expiry.
      const id = setInterval(() => {
        // TODO: Resolve Async issues .....
        setToken((prevToken) => {
          console.log(prevToken);
          fetchNewToken(prevToken);

          // HACK: Return the previous token to prevent a re-render (work around intermediate state due to async call.)
          return prevToken;
        });
      }, intervalInMillis);

      refreshIntervalRef.current = id;

      return () => clearInterval(refreshIntervalRef.current);
    }
  }, [refresh, invalidateToken]);

  const authenticate = (username, password) => {
    const requestOptions = JSON.stringify({ username, password });

    return new Promise(async (resolve, reject) => {
      const loginResponse = await login(requestOptions);

      if (loginResponse?.ok) {
        const {
          access_token: token,
          refresh_token: refreshToken,
          expires_in: expiresIn,
          roles = [],
          username,
        } = loginResponse.data;

        const { userId, merchantId, merchantName } = jwtDecode(token);

        const userResponse = await loadUser(token);

        if (userResponse?.ok) {
          const { isPasswordTemporary } = userResponse.data;

          const profile = {
            userId,
            username,
            merchantName,
            merchantId,
            isPasswordTemporary,
            permissions: roles,
          };

          setToken(token);
          setRefresh({ token: refreshToken, expiresIn });
          setLastActivity(Date.now());
          setProfile(profile);

          sessionStorage.setItem('token', token);
          sessionStorage.setItem('profile', JSON.stringify(profile));

          resolve(profile);
        } else {
          invalidateToken();
        }
      } else {
        invalidateToken();
        reject(requestOptions?.response?.data || 'Credentials could not be verified.');
      }
    });
  };

  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          //authenticationService.logout();
          //location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }

  return (
    <AuthContext.Provider
      value={{
        profile,
        token,
        setLastActivity,
        authenticate,
        invalidateToken: invalidateToken,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
