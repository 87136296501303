import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    background: '#ffffff',
  },
  appTitle: {},
  grow: {
    flexGrow: 1,
  },
  button: {
    height: '24px',
    fontSize: '11px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginLeft: '20px',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  menu: {
    width: 250,
    marginTop: 10,
    zIndex: theme.zIndex.tooltip,
  },
  suggestions: {
    width: 200,
    zIndex: theme.zIndex.tooltip,
  },
  badge: {
    backgroundColor: theme.palette.secondary.light,
  },
  username: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    textAlign: 'center',
  },
  logoStart: {
    color: theme.palette.red,
  },
  logoEnd: {
    color: theme.palette.primary.main,
  },
}));
