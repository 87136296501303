import axios from 'axios';

import * as endpoints from '../endpoints';

export const refreshToken = ({ data }) => {
  return axios({
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    baseURL: window._env_.REACT_APP_API_HOST,
    url: endpoints.AUTH.ACCESS_TOKEN,
    data: data,
  });
};

export const login = (data) => {
  return axios({
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    baseURL: window._env_.REACT_APP_API_SECURITY_HOST,
    url: endpoints.AUTH.LOGIN,
    data: data,
  });
};

export const loadUser = (token) => {
  return axios({
    headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
    method: 'GET',
    baseURL: window._env_.REACT_APP_API_SECURITY_HOST,
    url: endpoints.ACCOUNT.ROOT,
  });
};
