import React from 'react';
import { useAuth } from './AuthContext';

const ProfileContext = React.createContext();

function ProfileProvider(props) {
  const { profile } = useAuth();

  return <ProfileContext.Provider value={{ profile }} {...props} />;
}

function useProfile() {
  const context = React.useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
}

export { ProfileProvider, useProfile };
