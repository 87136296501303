import * as _ from 'lodash';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import { ReactComponent as IconDocument } from '../../assets/icons/icon-document.svg';

import useStyles from './styles';

export default function DevTools(props) {
  const classes = useStyles();

  const blockItems = [
    {
      title: 'api Swagger',
      // subTitle: 'Optional sub-head',
      items: [
        {
          title: 'Security',
          link: 'https://security-prod02.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
        {
          title: 'OMSAPI',
          link: 'https://api-prod02.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
        {
          title: 'Audit',
          link: 'https://audit-prod02.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
        {
          title: 'Alerts',
          link: 'https://alerts-prod02.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
      ],
    },
    {
      title: 'api-test Swagger',
      // subTitle: 'Optional sub-head',
      items: [
        {
          title: 'Security',
          link: 'https://security-test.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
        {
          title: 'OMSAPI',
          link: 'https://api-test.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
        {
          title: 'Audit',
          link: 'https://audit-test.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
        {
          title: 'Alerts',
          link: 'https://alerts-test.ext.prod.hub.rubyhas.com/swagger/views/swagger-ui',
        },
      ],
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.title}>Welcome to RubyDev</div>
      <Grid container spacing={2}>
        {_.map(blockItems, (item, index) => (
          <Grid item sm={6} md={4} lg={3} key={index}>
            <div className={classes.block}>
              <div className={classes.blockTitle}>{item.title}</div>
              <div className={classes.blockSubTitle}>{item.subTitle}</div>
              {_.map(item.items, (subItem, subIndex) => (
                <div key={subIndex}>
                  <a
                    className={classes.link}
                    href={subItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconDocument />
                    {subItem.title}
                  </a>
                </div>
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
