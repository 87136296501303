export const ORDERS = {
  ROOT: '/salesorders',
  EXPORT_ALL: '/salesorders/exports',
  IMPORT: '/salesorders/upload',
  IMPORT_RULES: '/import/rules/orders',
  UPDATE_STATUS: '/salesorders/action/status',
  RELEASE: '/salesorders/release',
};

export const PURCHASE_ORDERS = {
  ROOT: '/purchaseorders',
  EXPORT_ALL: '/purchaseorders/exports',
  IMPORT: '/purchaseorders/upload',
  IMPORT_RULES: '/import/rules/purchaseorders',
  CANCEL: '/purchaseorders/cancel',
};

export const RETURNS = {
  ROOT: '/returns',
  EXPORT_ALL: '/returns/exports',
  IMPORT: '/returns/upload',
};

export const PRODUCTS = {
  ROOT: '/inventory',
  EXPORT_ALL: '/inventory/exports',
  IMPORT: '/inventory/upload',
  IMPORT_RULES: '/import/rules/inventory',
};

export const SHIPMENTS = {
  ROOT: '/shipments',
  EXPORT_ALL: '/shipments/exports',
  IMPORT: '/shipments/upload',
  CARRIERS: '/shipments/carriers',
  SERVICES: '/shipments/services',
};

export const ACCOUNT = {
  ROOT: '/account',
  PASSWORD_RESET_INIT: '/account/forgot-password/init',
  PASSWORD_RESET_FINISH: '/account/forgot-password/finish',
};

export const ACTIVITY = {
  ROOT: '/activity',
  STATS: '/activity/stats',
  OPEN_ORDERS: '/activity/stats/openorders',
  SHIPPED_ORDERS: '/activity/stats/shipvia',
};

export const AUTH = {
  ACCESS_TOKEN: '/oauth/access_token',
  LOGIN: '/login',
};

export const ITEMS = '/items';
export const VERIFY_SKUS = '/items/skus/verify';
export const USERS = '/users';
export const ROLES = '/roles';
export const PERMISSIONS = '/permissions';
export const CUSTOM_VIEW = 'customview';
export const MERCHANTS = 'merchants';
export const ALERT = '/alert';
export const AUDIT_LOG = '/audit-logs';
export const ORDER_ATTACHMENTS = '/attachments';
export const FACILITIES = '/facilities';
export const EXPORT = '/export';
export const PEEKVIEWS_ORDERS = '/peekviews/orders';
export const SEARCH = '/search';
